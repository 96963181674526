import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: "home",
        component: () => import("../views/Home.vue")
    },
    {
        path: "/quiz/:url_name",
        component: () => import("../views/Quiz/QuizLayout.vue"),

        children: [
            {
                path: "",
                name: "quiz",
                component: () => import("../views/Quiz/QuizLoader.vue")
            },
            {
                path: ":board_id",
                name: "quiz-board",
                component: () => import("../views/Quiz/QuizBoard.vue")
            }
        ]
    },
    {
        path: "/quiz-tymczasowe-zakonczenie",
        name: "quiz-endpage",
        component: () => import("../views/Quiz/TempEndPage.vue")
    },
    {
        path: "/calendar",
        name: "calendar",
        component: () => import("../views/CalendarV.vue")
    },
    {
        path: "/create-appointment",
        name: "create-appointment",
        component: () => import("../views/CreateAppointment.vue")
    },
    {
        path: "/chats",
        name: "chats",
        component: () => import("../views/Chats.vue")
    },
    {
        path: "/chat/:id",
        name: "chat",
        component: () => import("../views/Chat.vue"),
        props: true
    },
    {
        path: "/settings",
        name: "settings",
        component: () => import("../views/Settings.vue")
    },
    {
        path: "/settings/personal-data",
        name: "personal-data",
        component: () => import("../views/PersonalData.vue")
    },
    {
        path: "/settings/login-data",
        name: "login-data",
        component: () => import("../views/LoginData.vue")
    },
    {
        path: "/settings/notifications",
        name: "notifications",
        component: () => import("../views/Notifications.vue")
    },
    {
        path: "/settings/payments",
        name: "payments",
        component: () => import("../views/Payments.vue")
    },
    {
        path: "/articles",
        name: "articles",
        component: () => import("../views/Articles.vue")
    },
    {
        path: "/appointments-history",
        name: "appointments-history",
        component: () => import("../views/AppointmentsHistory.vue")
    },
    {
        path: "/appointments/:id",
        name: "appointment",
        component: () => import("../views/Appointment.vue"),
        props: true
    },
    {
        path: "/ratings/:id",
        name: "rating",
        component: () => import("../views/Rating.vue"),
        props: true
    },
    {
        path: "/articles/:url_title",
        name: "article",
        component: () => import("../views/Article.vue"),
        props: true
    },
    {
        path: "/therapist-profile/:id",
        name: "therapist-profile",
        component: () => import("../views/TherapistProfile.vue"),
        props: true
    },
    {
        path: "/faq",
        name: "faq",
        component: () => import("../views/FAQ.vue")
    },
    {
        path: "/video-call",
        name: "video-call",
        component: () => import("../views/VideoCall.vue")
    },
    {
        path: "/test",
        name: "test",
        component: () => import("../views/Test.vue")
    },
    {
        path: "/:pathMatch(.*)*",
        name: "e404",
        component: () => import("../views/E404.vue")
    }
];

const router = createRouter({
    routes,
    history: createWebHistory()
});

router.beforeEach(to => {
    if (
        import.meta.env.PROD &&
        to.name &&
        ["quiz", "quiz-board", "e404"].indexOf(to.name.toString()) === -1
    ) {
        return {
            name: "e404"
        };
    }

    return true;
});

export default router;
