import { MenuItem } from "./types/general";
import { Appointment, CalendarDay, CancellationStep } from "./types/appointment";
import blikImage from "./assets/imgs/payments/blik.svg";
import transferImage from "./assets/imgs/payments/transfer.svg";
import cardImage from "./assets/imgs/payments/credit-card.svg";

export const colors: Record<string, string> = {
    primary: "#64A286",
    primary_light: "#E6F0E4",
    primary_dark: "#113A29",
    secondary: "#FFBF52",
    secondary_light: "#FFEADD",
    secondary_dark: "#DB9B60",
    error: "#ec2f23",
    success: "#14B8A6",
    red_accent: "#C2664E",
    gray_accent: "#f3f5f4",
    default: "#1a1a1a",
    white: "#fff"
};

export const menuItems: MenuItem[] = [
    {
        label: "Baza wiedzy",
        route: "/articles"
    },
    {
        label: "Kalendarz",
        route: "/calendar"
    },
    {
        label: "Czat z terapeutą",
        route: "/chats"
    },
    {
        label: "Ustawienia",
        route: "/settings"
    },
    {
        label: "Regulaminy i polityki",
        route: "/"
    },
    {
        label: "FAQ",
        route: "/faq"
    },
    {
        label: "Wyloguj się",
        route: "/"
    }
];

export const CALENDAR: CalendarDay[] = [
    {
        date: "2023-09-19",
        sessions: [
            "08:00 - 08:45",
            "17:00 - 17:45",
            "18:00 - 18:45",
            "19:00 - 19:45",
            "20:00 - 20:45"
        ]
    },
    {
        date: "2023-09-20",
        sessions: [
            "08:00 - 08:45",
            "09:00 - 09:45",
            "10:00 - 10:45",
            "16:00 - 16:45",
            "17:00 - 17:45",
            "18:00 - 18:45",
            "19:00 - 19:45",
            "20:00 - 20:45"
        ]
    },
    {
        date: "2023-09-21",
        sessions: [
            "08:00 - 08:45",
            "09:00 - 09:45",
            "10:00 - 10:45",
            "12:00 - 12:45",
            "13:00 - 13:35",
            "18:00 - 18:45",
            "19:00 - 19:45",
            "20:00 - 20:45"
        ]
    },
    {
        date: "2023-09-22",
        sessions: [
            "13:00 - 13:35",
            "14:00 - 14:45",
            "15:00 - 15:45",
            "16:00 - 16:45",
            "19:00 - 19:45",
            "20:00 - 20:45"
        ]
    },
    {
        date: "2023-09-23",
        sessions: [
            "08:00 - 08:45",
            "13:00 - 13:35",
            "16:00 - 16:45",
            "17:00 - 17:45",
            "20:00 - 20:45"
        ]
    },
    {
        date: "2023-09-24",
        sessions: [
            "08:00 - 08:45",
            "09:00 - 09:45",
            "11:00 - 11:45",
            "12:00 - 12:45",
            "14:00 - 14:45",
            "16:00 - 16:45",
            "19:00 - 19:45"
        ]
    },
    {
        date: "2023-09-25",
        sessions: [
            "08:00 - 08:45",
            "09:00 - 09:45",
            "10:00 - 10:45",
            "11:00 - 11:45",
            "12:00 - 12:45"
        ]
    },
    {
        date: "2023-09-26",
        sessions: [
            "11:00 - 11:45",
            "12:00 - 12:45",
            "16:00 - 16:45",
            "17:00 - 17:45",
            "18:00 - 18:45",
            "19:00 - 19:45",
            "20:00 - 20:45"
        ]
    }
];

export const settingsMenuItems: MenuItem[] = [
    {
        label: "Preferencje",
        route: "/"
    },
    {
        label: "Twoje dane",
        route: "/settings/personal-data"
    },
    {
        label: "Dane do logowania",
        route: "/settings/login-data"
    },
    {
        label: "Powiadomienia",
        route: "/settings/notifications"
    },
    {
        label: "Płatności",
        route: "/settings/payments"
    }
];

export const PAYMENTS_METHODS = [
    {
        name: "BLIK",
        value: "blik",
        image: blikImage
    },
    {
        name: "Przelewy24",
        value: "transfer",
        image: transferImage
    },
    {
        name: "Karta kredytowa",
        value: "card",
        image: cardImage
    }
];

export const MANAGEMENT_OPTIONS = [
    {
        id: "reschedule",
        title: "Przełóż wizytę",
        description_active: "Zmiana możliwa do 24H przed sesją",
        description_inactive: "Zmiana była możliwa do 24H przed sesją"
    },
    {
        id: "cancel",
        title: "Anuluj wizytę",
        description_active: "Zwrot pieniędzy jest możliwy do 24H przed sesją",
        description_inactive: "Zwrot pieniędzy był możliwy do 24H przed sesją"
    }
];

export const CANCEL_REASONS = [
    {
        value: "reason1",
        text: "Brak czasu"
    },
    {
        value: "reason2",
        text: "Brak pieniędzy"
    },
    {
        value: "reason3",
        text: "Brak chęci"
    },
    {
        value: "reason4",
        text: "Inny"
    }
];

export const CANCELLATION_STEPS: CancellationStep[] = [
    {
        title: "Marek, przykro nam, że anulujesz wizytę. Czy możemy Ci pomóc?",
        subtitle: "Jesteśmy online!",
        primaryButtonText: "Napisz do nas",
        secondaryButtonText: "Anuluj wizytę"
    },
    {
        title: "Terapeuta już zarezerwował czas na Twoją wizytę",
        text: "Marek, doceniamy Twoje zaufanie do nas, dlatego poinformowaliśmy terapeutę z wyprzedzeniem o Twojej wizycie.",
        alternative_text:
            "Nie otrzymasz zwrotu pieniędzy, ponieważ do sesji zostało mniej niż 24H.",
        primaryButtonText: "Kontynuuj terapię",
        secondaryButtonText: "Anuluj"
    },
    {
        title: "Prosimy o informację dlaczego rezygnujesz z wizyty",
        options: ["Option 1", "Option 2", "Option 3"],
        primaryButtonText: "Kontynuuj terapię",
        secondaryButtonText: "Anuluj"
    },
    {
        title: "Czy czegoś brakuje Ci w Risify?",
        text: "Twoja opinia jest dla nas bardzo ważna. Prosimy daj nam znać jeśli istnieje cokolwiek co by sprawiło, że korzystanie z Risify byłoby bardziej przyjemne i przynosiło Tobie więcej korzyści.",
        placeholder: "Wiadomość",
        primaryButtonText: "Kontynuuj terapię",
        secondaryButtonText: "Anuluj"
    },
    {
        title: "Czy na pewno chcesz anulować wizytę?",
        subtitle:
            "85% naszych klientów zauważa poprawę tylko jeśli kontynuują terapię przez dłuższy czas.",
        primaryButtonText: "Kontynuuj terapię",
        secondaryButtonText: "Anuluj wizytę"
    }
];

export const MEETINGS_DATA: { date: Date; meetings: Appointment[] }[] = [
    {
        date: new Date(2023, 9, 4),
        meetings: [
            {
                id: "meeting1",
                paidStatus: true,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: true
            }
        ]
    },
    {
        date: new Date(2023, 9, 15),
        meetings: [
            {
                id: "meeting2",
                paidStatus: false,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: false
            },
            {
                id: "meeting3",
                paidStatus: true,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: false
            },
            {
                id: "meeting4",
                paidStatus: true,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: false
            },
            {
                id: "meeting5",
                paidStatus: false,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: false
            },
            {
                id: "meeting6",
                paidStatus: true,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: false
            }
        ]
    },
    {
        date: new Date(2023, 9, 18),
        meetings: [
            {
                id: "meeting7",
                paidStatus: true,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: true
            },
            {
                id: "meeting8",
                paidStatus: false,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: false
            },
            {
                id: "meeting9",
                paidStatus: false,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: false
            }
        ]
    },
    {
        date: new Date(2023, 9, 28),
        meetings: [
            {
                id: "meeting10",
                paidStatus: true,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: true
            },
            {
                id: "meeting11",
                paidStatus: true,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: true
            }
        ]
    },
    {
        date: new Date(2023, 10, 10),
        meetings: [
            {
                id: "meeting12",
                paidStatus: false,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: false
            },
            {
                id: "meeting13",
                paidStatus: true,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: false
            }
        ]
    },
    {
        date: new Date(2023, 10, 16),
        meetings: [
            {
                id: "meeting14",
                paidStatus: true,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: true
            },
            {
                id: "meeting15",
                paidStatus: false,
                timestamp: 1633359600000,
                name: "Jan Kowalski",
                isEnded: false
            }
        ]
    }
];
